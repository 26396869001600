import "./styles.css";
import React, { useState, useEffect } from "react";
import Signature from "signature_pad";
import { FaEraser, FaSignature } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
// import Swal from 'sweetalert2';
import axios from "axios"


export default function App(props) {
 
  const url_base = 'https://app.easyclin.com.br/'
  // const url_base = 'http://192.168.15.8:8004/'

  function searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;
    
    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;
  
      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    console.log(obj)
    return obj;
  }

  const [signaturePad, setSignaturePad] = useState();

  const prm = searchToObject();
  const [xprm, setXprm] = useState(prm) 
  const [nome, setNome] = useState("...");
  const [assinar, setAssinar] = useState(true);
  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");

  async function handleCheck(){

    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector("canvas");
    
    let tempSignaturePad = new Signature(canvas, {
      backgroundColor: "rgb(255, 255, 255)", minWidth: 1, maxWidth: 1, 
    });
    setSignaturePad(tempSignaturePad);


    const headers = { 
      // 'Authorization': `Basic ${token}`,
      'Content-Type': '	application/json',
    };

    const formData = new FormData()
    formData.append('prm', xprm.pac);

    var url = url_base + 'api/signature/load/';

    await axios.post(url, formData, { headers: headers })
      .then((res) => {
        if (res.data.assinar) {
          setNome(res.data.nome);
          setAssinar(true)
        } 
        else {
          setNome(res.data.nome);
          setMsg1('Obrigado pela tua assinatura!')
          setAssinar(false)
        }
      })
      .catch((e) => {
        setMsg1('Erro!');
        setMsg2(e.stack)
      })
  }



  async function handleUpdate(){
  
    const formData = new FormData()
    formData.append('sig', signaturePad.toDataURL());
    formData.append('pac', xprm.pac);
    formData.append('fld', xprm.fld);

    const headers = { 
      // 'Authorization': `Basic ${token}`,
      'Content-Type': '	application/json',
    };
    
    var url = url_base + 'api/signature/save/';

    await axios.post(url, formData, { headers: headers })
    .then((res) => {
      setAssinar(false);
      setMsg1('Assinatura gravada!');
      setMsg2('Obrigado!')
    })
    .catch( function(e){
      setMsg1('Erro!');
      setMsg2(e)
    });
  }

  
  const handleClear = () => {
    signaturePad.clear();
  };
  
  useEffect(() => {
    handleCheck();
    var wrapper1 = document.getElementById("signature-pad"),
    canvas = wrapper1.querySelector("canvas")

    canvas.width = 500; //window.innerWidth;
    canvas.height = 100; //canvas.clientHeight;

    canvas.style.width = 500; //window.innerWidth;
    canvas.style.height = 100; //canvas.clientHeight;

    // // // // var ratio =  window.devicePixelRatio || 1;

    // // const size = 800;
    // // canvas.style.width = `${size}px`;
    // // // // canvas.style.height = `${size}px`;

    // const scale = window.devicePixelRatio;
    
    // var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    // canvas.width = canvas.offsetWidth * ratio;
    // canvas.height = canvas.offsetHeight * ratio;

    // // canvas.width = 800; //Math.floor(size * scale);
    // canvas.style.width = canvas.offsetWidth * ratio;
    // canvas.style.height = canvas.offsetWidth * ratio;
    // canvas.getContext("2d").scale(scale, scale);

  }, []);

  
  const styles1= {
    color: 'red',
    fontSize: '22px',
};
const styles2= {
  color: 'blue',
  fontSize: '22px',
};
  
return (
    <div className="app">
      <div>
        { assinar ? 
          <div>
            <div>
            <br/> 
              <span style={styles1}>{nome}</span><br/> 
              <span style={styles2}>Assine e grave por favor</span> 
            </div>
           
            <div id="signature-pad" className="signature-container" >
              <canvas className="signature-canvas"></canvas>
            </div>
            <div>
              <button onClick={handleUpdate}>
                <FaSignature /> Gravar
              </button>
              <button onClick={handleClear}>
                <FaEraser /> Refazer
              </button>
            </div>
          </div>
          :  
          <div>
            <br/>
            <span style={styles1}>Já assinado! por:</span><br/><br/><br/> 
            <span style={styles1}>{nome}</span><br/><br/><br/> 
            <span style={styles2}>{msg1}</span> <br/><br/> 
            <span style={styles2}>{msg2}</span> 
          </div>
        }
      </div>     
    </div>
  );
}
